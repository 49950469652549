.wpIcon {
  position: fixed;
  bottom: 2%;
  right: 2%;
  font-size: 20px;
  z-index: 2;
  height: 50px;
  width: 50px;
  background-color: rgba(0, 0, 0, 0.605);
  border-radius: 50%;
  color: #fff;
  transition: 0.3s;
  padding: 0.8rem;
}
.wpIcon:hover {
  background-color: green !important;
  padding: 0.3rem;
}
#textDecoration {
  color: transparent;
}

