a{
  text-decoration: none !important;
  color: white !important;
}
.title
{
  text-align: center;
  margin-top: 20px;
  text-decoration:underline !important;
  font-size:xx-large;
  font-weight: 800;
}
.card
{
  border: 1px rgb(0, 0, 0) dashed !important;
}