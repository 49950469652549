/*  */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:700");
#primary {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#primary li {
  margin: 0 20px;
  position: relative;
}
#primary li a {
  text-decoration: none;
  display: block;
  padding: 5px 0;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 1;
  position: relative;
  font-weight: bold;
  z-index: 1;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #9155fd,
    #9155fd 50%,
    #ffffff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  transition: all 0.3s ease-in-out;
}
#primary li a:before {
  display: block;
  content: "";
  width: 0;
  height: 3px;
  bottom: 5px;
  left: 0;
  bottom: -3px;
  z-index: 0;
  position: absolute;
  background: #9155fd;
  transition: all 0.3s ease-in-out;
}
#primary li a:hover {
  background-position: 0%;
}
#primary li a:hover:before {
  width: 100%;
}
