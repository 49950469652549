.img_edit{
    height: 250px;
    width: 250px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    object-fit: cover;
}
.img_editbg
{
    height: 200px;
    width: 200px;
    object-fit: cover;
    border: 1px rgb(0, 51, 255) dashed;
    border-radius:50%;
}